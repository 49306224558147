"use client";

import { useTheme } from "@/contexts/ThemeContext";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import { GamepadIcon, HomeIcon } from "lucide-react";

export default function Custom404() {
	const { theme } = useTheme();

	return (
		<div className="flex flex-col items-center justify-center min-h-screen bg-background text-foreground">
			<GamepadIcon
				className="w-20 h-20 text-muted-foreground mb-8"
				aria-hidden="true"
			/>
			<h1 className="text-4xl font-bold mb-4">404 - Page Non Trouvée</h1>
			<p className="text-xl mb-8 text-center max-w-md">
				Oups ! La page que vous recherchez n&apos;existe pas ou a été déplacée.
			</p>
			<Button>
				<Link href="/" className="flex items-center">
					<HomeIcon className="mr-2 h-4 w-4" aria-hidden="true" />
					<span>Retour à l&apos;Accueil</span>
				</Link>
			</Button>
		</div>
	);
}
