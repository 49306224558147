"use client";

import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cn } from "@/lib/utils";

// Define button variants with Tailwind classes for ghost styles using color and opacity utilities
const buttonVariants = {
	primary: "btn btn-primary",
	secondary: "btn btn-secondary",
	accent: "btn btn-accent",
	dark: "btn btn-dark",
	primaryOutline: "btn btn-outline-primary",
	secondaryOutline: "btn-outline-secondary",
	accentOutline: "btn btn-outline-accent",
	darkOutline: "btn btn-outline-dark",
	ghostPrimary: "btn btn-ghost-primary",
	ghostSecondary: "btn btn-ghost-secondary",
	ghostAccent: "btn btn-ghost-accent",
	ghostDark: "btn btn-ghost-dark",
};

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	variant?: keyof typeof buttonVariants;
	size?: "default" | "sm" | "lg" | "icon";
	asChild?: boolean;
	icon?: React.ReactNode;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			className,
			variant = "primary",
			size = "default",
			asChild = false,
			icon,
			children,
			...props
		},
		ref
	) => {
		const Comp = asChild ? Slot : "button";
		return (
			<Comp
				className={cn(
					buttonVariants[variant],
					size === "sm" && "h-9 rounded-md px-3",
					size === "lg" && "h-11 rounded-md px-8",
					size === "icon" && "h-10 w-10",
					"flex items-center justify-center gap-2",
					className
				)}
				ref={ref}
				{...props}
			>
				{icon && <span className="icon">{icon}</span>}
				{children}
			</Comp>
		);
	}
);
Button.displayName = "Button";

export { Button };
